import { Link } from "gatsby"

import React from "react"
import styled from "styled-components"
import { palette } from "../_variables"
import { respondTo } from "../_respondTo"

import CookieConsent from "react-cookie-consent"

const FooterWrapper = styled.footer`
  width: 100%;
  background-color: ${palette.primary};
  text-align: center;
  padding: 0.75em 0.75em 2em 0.75em;
`

const FooterText = styled.p`
  color: white;
  margin: 0 auto 0.5em auto;
  font-size: small;
  line-height: 1.3;
`

const FooterLinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 960px;
  margin: 0 auto;
  color: white;
`

const FooterLink = styled(Link)`
  color: white;
  font-size: small;
  margin: 0.5em 1.5em;
`

function Footer() {
  return (
    <FooterWrapper>
      <FooterLinkWrapper style={{ marginBottom: "1.5em" }}>
        <FooterLink to={"/important-information"}>
          Important Information
        </FooterLink>
        <FooterLink to={"/privacy-policy"}>Privacy Policy</FooterLink>
        <FooterLink to={"/privacy-policy#cookies"}>Cookie Policy</FooterLink>

        <FooterLink to={"/complaints-policy"}>Complaints Policy</FooterLink>

        <FooterLink to={"/important-information#fos"}>
          Financial Ombudsman Service
        </FooterLink>
      </FooterLinkWrapper>

      <FooterText>
        {" "}
        Copyright © {new Date().getFullYear()}, Law Society (NI) Financial
        Advice Limited. All Rights Reserved.
      </FooterText>

      <FooterText>
        Law Society (NI) Financial Advice Limited is authorised and regulated by
        the Financial Conduct Authority.{" "}
        <a href="https://register.fca.org.uk/" style={{ color: "white" }}>
          FCA registered number 141726.
        </a>
      </FooterText>
      <FooterText>
        Registered in Northern Ireland with company number NI23143. Registered
        office is located at 42-44 Rosemary Street, Belfast, Northern Ireland,
        BT1 1QE.
      </FooterText>

      <FooterText>
      Law Society (NI) Financial Advice Limited is part of the {" "}
        <a href="https://truewg.com/" style={{ color: "white" }}>
        True Wealth Group
        </a>, which is part of the Succession Wealth Group which is part of the Aviva Group of Companies and is authorised and regulated by the Financial Conduct Authority.
      </FooterText>

      <CookieConsent
        style={{
          fontSize: "0.75em",
          padding: "0.05em",
          backgroundColor: "rgba(88,88,88,0.85)",
        }}
        buttonStyle={{ margin: "0 auto", padding: "2 5", order: "0" }}
        contentStyle={{ margin: "0" }}
        expires={365}
      >
        This website uses cookies to monitor its use.
      </CookieConsent>
    </FooterWrapper>
  )
}

export default Footer
